<template>
    <div class="container mt-12">
        <div class="row bg-grey p-4">
            <div class="col-sm-6">
                <canvas id="myChart" width="400" height="400"></canvas>
            </div>
            <div class="col-sm-6">
                <div class="row seccion-home-inmo mb-4">
                    <div class="row mt-4">
                        <div class="col-sm-6">
                            Total de simulaciones
                        </div>
                        <div class="col-sm-6">
                            2123
                        </div>
                     </div>
                     <div class="row mt-4">
                        <div class="col-sm-6">
                            Cr&eacute;ditos firmados
                        </div>
                        <div class="col-sm-6">
                            2123
                        </div>
                    </div>
                </div>
                
                <div class="row seccion-home-inmo mt-4">
                    <div class="row mt-4">
                        <div class="col-sm-6">
                        Clientes nuevos
                        </div>
                        <div class="col-sm-6">
                            2123
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-6">
                            Mis simulaciones
                        </div>
                        <div class="col-sm-6">
                            2123
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-6">
                            Oficina
                        </div>
                        <div class="col-sm-6">
                            2123
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js'
export default {
    components:{
        'chart.js':Chart
    }
}

var ctx = document.getElementById('myChart');
var myChart = new Chart(ctx, {
    type: 'bar',
    data: {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [{
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
        }]
    },
    options: {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    }
});
</script>

<style>

.seccion-home-inmo{
    background-color: lightgray;
    border-radius: 7px;
    padding: 15px;
}

</style>